import React from "react";
// import ReactDOM from 'react-dom';
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/App";
import * as serviceWorker from "./serviceWorker";
import Routes from "./RouterPage";
import Students from "./pages/Students/Students";
import { BrowserRouter } from "react-router-dom";
// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
      {/* <Routes /> */}
      {/* <Students />  */}
      {/* <App />  */}
    </React.StrictMode>
  </>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
