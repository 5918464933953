import React, { useState, useEffect, useLayoutEffect } from "react";
import StudentForm from "./StudentForm";
import PageHeader from "../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import * as studentService from "../../services/studentService";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../components/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
// import {useParams} from 'react-router-dom';
import { Grid } from "@material-ui/core";
import http from "../../common/httpCommon";
import { id } from "date-fns/locale";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    // position: 'absolute',
    // right: '10px'
  },
  "@media (max-width: 767px)": {
    searchInput: {
      width: "100%",
    },
  },
}));

export default function Students(props) {
  const headCells = [
    { id: "srName", label: "Student Name" },
    { id: "srClass", label: "Class" },
    { id: "srFatherName", label: "Father Name" },
    { id: "srAddress", label: "Address" },
    { id: "srMobile", label: "Mobile Number" },
    { id: "actions", label: "Actions", disableSorting: true },
  ];
  props.data.data1 &&
    headCells.splice(5, 0, { id: "srData1", label: props.data.data1 });
  props.data.data2 &&
    headCells.splice(6, 0, { id: "srData2", label: props.data.data2 });
  props.data.data3 &&
    headCells.splice(7, 0, { id: "srData3", label: props.data.data3 });

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  console.log("ID:", props.data.id);
  // debugger;
  const [records, setRecords] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    http
      .get("/StudentIDCardRecord/" + props.data.id)
      .then((result) => {
        setRecords(result.data);
        // setNotify({
        //   isOpen: true,
        //   message: "Submitted Successfully",
        //   type: "success",
        // });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });
  };
  //getData();
  console.log("after useeffect", records);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.srName.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addOrEdit = (student, resetForm) => {
    //alert("Student info: " + student.id + "Std Full Detail: " + student);
    console.log("Student info10: ", student);
    if (student.id == 0 || student.id == null) {
      http
        .post("/StudentIDCardRecord", student)
        .then((result) => {
          getData();
          resetForm();
          setRecordForEdit(null);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: "something wrong happened with server or network",
            type: "error",
          });
        });
    }
    //studentService.insertStudent(student);
    else {
      console.log("Edit Page", student.id);
      http
        .put("/StudentIDCardRecord/" + student.id, student)
        .then((result) => {
          getData();
          resetForm();
          setRecordForEdit(null);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: "something wrong happened with server or network",
            type: "error",
          });
        });
    }
    //studentService.updateStudent(student);

    // setRecords(studentService.getAllStudents());

    // setNotify({
    //   isOpen: true,
    //   message: "Submitted Successfully",
    //   type: "success",
    // });
  };
  const onFinalSubmission = (scID) => {
    http
      .put("/StudentIDCardRecord/SubmitStudentIDCardRecord/" + scID)
      .then((result) => {
        getData();
        //resetForm();
        //setRecordForEdit(null);
        //setOpenPopup(false);
        setNotify({
          isOpen: true,
          message: "Final Submission Successfully",
          type: "success",
        });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });
  };
  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    http
      .delete("/StudentIDCardRecord/" + id)
      .then((result) => {
        if (result.status === 200) {
          getData();
          setRecordForEdit(null);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else
          setNotify({
            isOpen: true,
            message: "Record not found",
            type: "success",
          });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });
    //studentService.deleteStudent(id);
    // setRecords(studentService.getAllStudents());
    //setRecords(studentService.GetAllStudents());
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  return (
    <>
      {console.log("Students record check after click:", records)}
      <PageHeader
        title={props.data.sName}
        subTitle="Student ID Card Form"
        // subTitle={params.id}
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent} style={{ overflowX: "auto" }}>
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                label="Search Students"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Button
                text="Add New"
                variant="outlined"
                startIcon={<AddIcon />}
                className={classes.newButton}
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                  console.log("records1 called: ", records);
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        {/* {records.length && <FormHelperText>no records</FormHelperText>} */}
        <TblContainer>
          <TblHead>
            <TableRow key={headCells.id}>
              <TableCell>{headCells.srName}</TableCell>
              <TableCell>{headCells.srClass}</TableCell>
              <TableCell>{headCells.srFatherName}</TableCell>
              <TableCell>{headCells.srAddress}</TableCell>
              <TableCell>{headCells.srMobile}</TableCell>
              <TableCell>{headCells.srData1}</TableCell>
              <TableCell>{headCells.srData2}</TableCell>
              <TableCell>{headCells.srData3}</TableCell>
            </TableRow>
          </TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.srName}</TableCell>
                <TableCell>{item.srClass}</TableCell>
                <TableCell>{item.srFatherName}</TableCell>
                <TableCell>{item.srAddress}</TableCell>
                <TableCell>{item.srMobile}</TableCell>
                {console.log("prop1 data", props.data)}
                {props.data.data1 && <TableCell>{item.srData1}</TableCell>}
                {props.data.data2 && <TableCell>{item.srData2}</TableCell>}
                {props.data.data3 && <TableCell>{item.srData3}</TableCell>}
                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id);
                        },
                      });
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Button
                text="Final Submission"
                variant="outlined"
                endIcon={<ChevronRightIcon />}
                className={classes.newButton}
                onClick={() => {
                  setConfirmDialog({
                    isOpen: true,
                    title: "Are you sure to submit all students record?",
                    subTitle: "You can't undo this operation",
                    onConfirm: () => {
                      onFinalSubmission(props.data.id);
                    },
                  });
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </Paper>
      <Popup
        title="Student ID Card Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <StudentForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          data={props}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
  // }
}
