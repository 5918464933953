import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import PermMediaOutlinedIcon from "@material-ui/icons/PermMediaOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
// import {useParams} from 'react-router-dom';
import { Grid } from "@material-ui/core";
import http from "../../common/httpCommon";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    // position: 'absolute',
    // right: '10px'
  },
  "@media (max-width: 767px)": {
    searchInput: {
      width: "100%",
    },
  },
}));

export default function DownloadForm(props) {
  const headCells = [
    { id: "sName", label: "School Name" },
    { id: "srPublishedDate", label: "Final Submission Date" },
    { id: "actions", label: "Download/Delete", disableSorting: true },
  ];

  const classes = useStyles();
  // debugger;
  const [records, setRecords] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    http
      .get("/StudentIDCardDownload")
      .then((result) => {
        setRecords(result.data);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });
  };

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const schoolName = (sName) => {
    console.log("Name: ", sName);
    console.log("Name Length: ", sName.length);
    if (sName && sName.length > 8) {
      sName = sName.split(" ").join("-").toLowerCase().substring(0, 8);
      sName = sName.split(".").join("-").toLowerCase().substring(0, 8);
      console.log("Name1: ", sName);
      console.log("Name1 Length: ", sName.length);
    }
    return sName;
  };
  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.sName.toLowerCase().includes(target.value)
          );
      },
    });
  };
  const onExcelDownload = (xdata) => {
    const link = document.createElement("a");
    http
      .get("/Excel", {
        params: {
          id: xdata.scID,
          batchCode: xdata.srPublishBatchCode,
        },
        responseType: "blob",
      })
      .then((result) => {
        link.href = URL.createObjectURL(
          new Blob([result.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        link.download =
          "ICardRecords-" + xdata.srPublishBatchCode + schoolName(xdata.sName);
        link.click();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });
  };

  const onImageDownload = (xdata) => {
    const link = document.createElement("a");
    http
      .get("/DownloadImage", {
        params: {
          id: xdata.scID,
          batchCode: xdata.srPublishBatchCode,
        },
        responseType: "blob",
      })
      .then((result) => {
        link.href = URL.createObjectURL(
          new Blob([result.data], {
            type: "application/zip",
          })
        );
        link.download =
          "ICardImages-" + xdata.srPublishBatchCode + schoolName(xdata.sName);
        link.click();
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });
  };
  const onDelete = (xdata) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    http
      .delete("/DownloadImage", {
        params: {
          id: xdata.scID,
          batchCode: xdata.srPublishBatchCode,
        },
      })
      .then((result) => {
        if (result.status === 200) {
          getData();
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else
          setNotify({
            isOpen: true,
            message: "Record not found",
            type: "success",
          });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  return (
    <>
      <PageHeader
        title="Admin Dashboard"
        subTitle="Download Form"
        // subTitle={params.id}
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent} style={{ overflowX: "auto" }}>
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                label="Search School"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <TblContainer>
          <TblHead>
            <TableRow key={headCells.id}>
              <TableCell>headCells.sName</TableCell>
              <TableCell>headCells.srPublishedDate</TableCell>
            </TableRow>
          </TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.sName}</TableCell>
                <TableCell>{item.srPublishedDate}</TableCell>

                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      onExcelDownload(item);
                    }}
                  >
                    <CloudDownloadOutlinedIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      onImageDownload(item);
                    }}
                  >
                    <PermMediaOutlinedIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item);
                        },
                      });
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>

      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
  // }
}
