import React, { useState, useEffect, useLayoutEffect } from "react";
import SchoolForm from "../Admin/SchoolForm";
import PageHeader from "../../components/PageHeader";
import PeopleOutlineTwoToneIcon from "@material-ui/icons/PeopleOutlineTwoTone";
import {
  Paper,
  makeStyles,
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  FormHelperText,
} from "@material-ui/core";
import useTable from "../../components/useTable";
import Controls from "../../components/controls/Controls";
import { Search } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import Popup from "../../components/Popup";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import CloseIcon from "@material-ui/icons/Close";
import Notification from "../../components/Notification";
import ConfirmDialog from "../../components/ConfirmDialog";
// import {useParams} from 'react-router-dom';
import { Grid } from "@material-ui/core";
import http from "../../common/httpCommon";
import { id } from "date-fns/locale";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(5),
    padding: theme.spacing(3),
  },
  searchInput: {
    width: "75%",
  },
  newButton: {
    // position: 'absolute',
    // right: '10px'
  },
  "@media (max-width: 767px)": {
    searchInput: {
      width: "100%",
    },
  },
}));

export default function School(props) {
  // const columns = [
  //   {
  //     field: 'id',
  //     headerName: 'ID',
  //     width: 150,
  //     renderCell: (params) => {
  //       return <a href='${params.id}'>${params.id}</a>;
  //     },
  //   },
  //   { field: 'inviteId', headerName: 'Invite Id', width: 150 },
  // ];
  const headCells = [
    { id: "sName", label: "School Name" },
    {
      id: "sLink",
      label: "School Links",
      // render: (rowData) => <link href="#">hello</link>,
    },
    { id: "col1", label: "Col1" },
    { id: "col2", label: "Col2" },
    { id: "col3", label: "Col3" },
    { id: "col4", label: "Col4" },
    { id: "col5", label: "Col5" },
    { id: "col6", label: "Col6" },
    { id: "col7", label: "Col7" },
    { id: "col8", label: "Col8" },
    { id: "col9", label: "Col9" },
    { id: "actions", label: "Actions", disableSorting: true },
  ];

  const classes = useStyles();
  const [recordForEdit, setRecordForEdit] = useState(null);
  // debugger;
  const [records, setRecords] = useState([]);
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    http
      .get("/StudentIDCard")
      .then((result) => {
        setRecords(result.data);
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });
  };

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { TblContainer, TblHead, TblPagination, recordsAfterPagingAndSorting } =
    useTable(records, headCells, filterFn);

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value == "") return items;
        else
          return items.filter((x) =>
            x.sName.toLowerCase().includes(target.value)
          );
      },
    });
  };

  const addOrEdit = (school, resetForm) => {
    if (school.id == 0 || school.id == null) {
      http
        .post("/StudentIDCard", school)
        .then((result) => {
          getData();
          resetForm();
          setRecordForEdit(null);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: "something wrong happened with server or network",
            type: "error",
          });
        });
    } else {
      http
        .put("/StudentIDCard/" + school.id, school)
        .then((result) => {
          getData();
          resetForm();
          setRecordForEdit(null);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: "Submitted Successfully",
            type: "success",
          });
        })
        .catch((error) => {
          setNotify({
            isOpen: true,
            message: "something wrong happened with server or network",
            type: "error",
          });
        });
    }
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    http
      .delete("/StudentIDCard/" + id)
      .then((result) => {
        if (result.status === 200) {
          getData();
          setRecordForEdit(null);
          setOpenPopup(false);
          setNotify({
            isOpen: true,
            message: "Deleted Successfully",
            type: "success",
          });
        } else
          setNotify({
            isOpen: true,
            message: "Record not found",
            type: "success",
          });
      })
      .catch((error) => {
        setNotify({
          isOpen: true,
          message: "something wrong happened with server or network",
          type: "error",
        });
      });

    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "error",
    });
  };

  return (
    <>
      <PageHeader
        title="Admin Dashboard"
        subTitle="School Form"
        // subTitle={params.id}
        icon={<PeopleOutlineTwoToneIcon fontSize="large" />}
      />
      <Paper className={classes.pageContent} style={{ overflowX: "auto" }}>
        <Toolbar>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <Controls.Input
                label="Search School"
                className={classes.searchInput}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
                onChange={handleSearch}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controls.Button
                text="Add New"
                variant="outlined"
                startIcon={<AddIcon />}
                className={classes.newButton}
                onClick={() => {
                  setOpenPopup(true);
                  setRecordForEdit(null);
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <TblContainer>
          <TblHead>
            <TableRow key={headCells.id}>
              <TableCell>headCells.sName</TableCell>
              <TableCell>headCells.sLink</TableCell>
              <TableCell>headCells.col1</TableCell>
              <TableCell>headCells.col2</TableCell>
              <TableCell>headCells.col3</TableCell>
              <TableCell>headCells.col4</TableCell>
              <TableCell>headCells.col5</TableCell>
              <TableCell>headCells.col6</TableCell>
              <TableCell>headCells.col7</TableCell>
              <TableCell>headCells.col8</TableCell>
              <TableCell>headCells.col9</TableCell>
            </TableRow>
          </TblHead>
          <TableBody>
            {recordsAfterPagingAndSorting().map((item) => (
              <TableRow key={item.id}>
                <TableCell>{item.sName}</TableCell>
                <TableCell>
                  <Link
                    style={{
                      color: "rgba(0, 0, 0, 0.87)",
                    }}
                    to={item.sSharedLink}
                    target="blank"
                  >
                    {item.sSharedLink}
                  </Link>
                </TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Class</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Father Name</TableCell>
                <TableCell>Phone No.</TableCell>
                <TableCell>Photo</TableCell>
                <TableCell>{item.data1}</TableCell>
                <TableCell>{item.data2}</TableCell>
                <TableCell>{item.data3}</TableCell>

                <TableCell>
                  <Controls.ActionButton
                    color="primary"
                    onClick={() => {
                      openInPopup(item);
                    }}
                  >
                    <EditOutlinedIcon fontSize="small" />
                  </Controls.ActionButton>
                  <Controls.ActionButton
                    color="secondary"
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: "Are you sure to delete this record?",
                        subTitle: "You can't undo this operation",
                        onConfirm: () => {
                          onDelete(item.id);
                        },
                      });
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </Controls.ActionButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TblContainer>
        <TblPagination />
      </Paper>
      <Popup
        title="School Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <SchoolForm
          recordForEdit={recordForEdit}
          addOrEdit={addOrEdit}
          //data={props}
        />
      </Popup>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
  // }
}
