import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Controls from "../../components/controls/Controls";
import { useForm, Form } from "../../components/useForm";

const initialFValues = {
  sName: "",
  id: 0,
  data1: "",
  data2: "",
  data3: "",
  sSharedLink: "",
  sLink: "",
};

export default function SchoolForm(props) {
  const { addOrEdit, recordForEdit } = props;

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("sName" in fieldValues)
      temp.sName = fieldValues.sName ? "" : "This field is required.";
    setErrors({
      ...temp,
    });

    if (fieldValues == values) {
      return Object.values(temp).every((x) => x == "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm);
    }
  };

  useEffect(() => {
    if (recordForEdit != null)
      setValues({
        ...recordForEdit,
      });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Controls.Input
            name="sName"
            label="School Name"
            value={values.sName}
            onChange={handleInputChange}
            error={errors.sName}
          />
          <Controls.Input
            name="data1"
            label="Extra Column1"
            value={values.data1}
            onChange={handleInputChange}
            error={errors.data1}
          />

          <Controls.Input
            label="Extra Column2"
            name="data2"
            value={values.data2}
            onChange={handleInputChange}
            error={errors.data2}
          />
          <Controls.Input
            label="Extra Column3"
            name="data3"
            value={values.data3}
            onChange={handleInputChange}
            error={errors.data3}
          />
          <div>
            <Controls.Button type="submit" text="Submit" />
            <Controls.Button text="Reset" color="default" onClick={resetForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
